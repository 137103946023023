import axios from "axios";

class Api {
  static getBaseUrl = () => {
    if (window.REACT_APP_build_env === "prod") {
      return "https://bspayment.collegeboard.org/bspayment-prod/payment";
    } else {
      return (
        "https://ecom-apis.ecom-nonprod.collegeboard.org/bspayment-" +
        window.REACT_APP_build_env +
        "/payment"
      );
    }
  };

  static getPaymentConfig = (token) => {
    var url = this.getBaseUrl() + "/" + token;
    return doGet(url);
  };

  static initPayPalPayment = (request) => {
    var url = this.getBaseUrl() + "/init";
    return doPost(url, request);
  };

  static postPayPalPayment = (request) => {
    var url = this.getBaseUrl();
    return doPost(url, request);
  };

  static processPayment = (request) => {
    var url = this.getBaseUrl();
    return doPost(url, request);
  };
}
/**
 * Executes HTTP get request
 * @param url the request URL.
 * @param id_token the token
 * @returns {Promise<Object>} a promise containing the service response object.
 */
async function doGet(url) {
  return axios
    .get(url, {
      headers: {
        Accept: "application/json",
      },
    })
    .then((response) => {
      //console.log("Response detail: ", response);
      return response.data;
    })
    .catch((error) => {
      console.log("axios get error: ", error);
      throw error;
    });
}

async function doPost(url, request) {
  return axios({
    url: url,
    method: "post",
    data: request,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      //console.log("Response detail: ", res);
      return res.data;
    })
    .catch((error) => {
      //console.log(error);
      throw error;
    });
}

export default Api;
