import React, {Component} from 'react';
import $ from 'classnames';
import { isEqual, merge } from 'lodash';
import { ApricotUpdate, getCreditCardData } from '../../Utility/utils';
import styles from './styles.module.scss';

const isRequiredValidator = (value) => { 
  let test = true;

  if(value.length === 0) { 
    test = false; 
  } 
  
  return {
    test: "Is Required",
    error: "Error:This field is required",
    status: test
  }; 
}

const isNumericValidator = (value) => {
  let test = new RegExp(/^[0-9]*$/);
  return {
    test: "Is Numeric",
    error: 'This field must be a number',
    status: test.test(value)
  }; 
}
const isEmailValidator = (value) => {
  let test = new RegExp(/^.+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
  return {
    test: "Is Email",
    error: 'Please enter a valid email address',
    status: test.test(value)
  };
}

const isPhoneValidator = (value) => {
  let test = new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
  return {
    test: "Is Phone Number",
    error: 'Please enter a valid phone number',
    status: test.test(value)
  };
}

const isCreditCardTypeValidator = (value, additionalData) => {
  let errorMessage = '';
  let status = false;

  const validateIIN = (v, iin) => {
    let valid = false;


    iin.forEach((i) => {
      if(('' + v).charAt(0) === i) {
        valid = true;
      }
    });

    return valid;
  }

  const validateRange = (v, range) => {
    if(('' + v).length > range) {
      return false;
    }

    return true;
  }

  if(!additionalData.creditCardType || additionalData.creditCardType === 'none') {
    errorMessage = 'Please select a Credit Card';

  } else {
    let card = getCreditCardData(additionalData.creditCardType);

    if(!validateIIN(value, card.IIN) && value.length > 0) {
      errorMessage = 'This number does not match the Card Type';
    }

    if(!validateRange(value, card.range)) {
      errorMessage = 'This is an invalid Credit Card Number';
    }
  }

  if(errorMessage === '') {
    status = true;
  } else {
    status = false;
  }

  return {
    test: "Is Credit Card Type",
    error: errorMessage,
    status: status
  }
}

const validatorsDatabase = {
  required: {"label": "required", "test": isRequiredValidator},
  numeric: {"label": "numeric", "test": isNumericValidator},
  creditcard: {"label": "creditCard", "test": isCreditCardTypeValidator},
  phone: {"label": "phone", "test": isPhoneValidator},
  email: {"label": "email", "test": isEmailValidator}
}

const getError = (errors) => {
  if(errors.length) {
    const error = errors[errors.length - 1];
    return error.error;
  }

  return '';
}

const getValidators = (validationList) => {
  let validators = [];
  validationList.forEach((v) => {
    if(validatorsDatabase[v]) {
      validators.push(validatorsDatabase[v]);
    }
  });
  return validators;
}

export
class ApricotFormGroupSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasErrors: this.props.errorMessage ? true : false,
      isDirty: this.props.defaultValue ? true : false,
      errors: [],
      errorMessage: this.props.errorMessage ? this.props.errorMessage : 'There is an error'
    }

    this.selectRef = React.createRef();
    this.value = this.props.defaultValue;
    if(!this.props.defaultValue) {
      if(this.props.children) {
        if(this.props.children.length) {
          this.value = this.props.children[0].props.value;
        } else {
          this.value = this.props.children.props.value;
        }
      } 
    }
  }

  render() {
    const { id, label, isRequired, defaultValue, onChange } = this.props;
    const { errorMessage } = this.state;

    const validate = (e) => {
      let value = this.value = e.target.value;
      let hasError = false;
      let message = false;
      
      if(value === 'none' || !value) {
        if(isRequired) {
          hasError = true;
          message = 'Error:This field is required';
        } else {
          hasError = false;
          message = false;
        }
      }

      this.setState({
        hasErrors: hasError,
        errorMessage: message
      })

      if(onChange) {
        onChange({id: id, value: value});
      }
    }

    const onBlurHandler = (e) => {
      validate(e);
    }

    const onChangeHandler = (e) => {
      validate(e);
    }

    return (
      <div className={$({'has-error': this.state.hasErrors}, "form-group")}>
        <label className={$({"cb-required": isRequired})} htmlFor={id}>{label}</label>
        <select 
          ref={this.selectRef}
          name={id} 
          id={id} 
          className="form-control" 
          defaultValue={defaultValue || this.value} 
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
        > 
          {this.props.children}
        </select>
        { (this.state.hasErrors === true) && <p className="cb-error-msg">{errorMessage}</p> }
      </div>
    )
  }

  componentDidMount() {
    ApricotUpdate();
  }

  componentDidUpdate(prevProps) {
    /**
     * Notify Apricot of change bound the jquery
     */
    let $ = window.$;
    let elm = $('#' + this.props.id);
    elm.change();

    /**
     * Update error handling
     */
    if(!isEqual(this.props.errorInfo, prevProps.errorInfo) || this.props.errorMessage !== prevProps.errorMessage) {
      this.setState({
        hasErrors: this.props.errorMessage ? true : false,
        errorMessage: this.props.errorMessage
      });
    }
  }
}

export
class ApricotFormGroupInput extends Component {
  constructor(props) {
    super(props)

    let validationList = this.props.validators || [];
    if(this.props.isRequired) { validationList.push('required'); }
    this.validators = getValidators(validationList);

    this.inputRef = React.createRef();
    this.value = this.props.defaultValue;
    

    this.updateState = (newState, validate = false, additionalData) => {
      let updatedState = merge(this.state, newState);
      this.setState(updatedState);
      if(validate) {
        this.validate({
          target: {
            value: this.inputRef.current.value
          }
        }, additionalData);
      }
    }

    this.state = {
      type: this.props.isSecret === true ? 'password' : 'text',
      hasErrors: this.props.errorMessage ? true : false,
      isDirty: this.props.defaultValue ? true : false,
      errors: [],
      errorMessage: this.props.errorMessage ? this.props.errorMessage : 'There is an error'
    }
  }

  validate(e, updatedData) {
    const { id, onChange } = this.props; 
    const value = e.target.value;
    let errors = [];

    const additionalData = merge(this.props.additionalData, updatedData);

    this.validators.forEach((v) => {
      let validator = v;
      let test = validator.test(value, additionalData);
      if(!test.status) {
        errors.push(test);
      }
    });

    let state = {
      hasErrors: !!errors.length,
      errors: errors,
      errorMessage: getError(errors)
    }

    this.setState(state);
    if(onChange) {
      let newState = merge({id: id, value: value}, state);
      onChange(newState);
    } 
  }

  render() {
    const { id, label, maxLength, isRequired, autoComplete, defaultValue, isSecret } = this.props;
    const { hasErrors, errorMessage, type } = this.state;

    const onSecretHandler = (e, display) => {
      if(isSecret) {
        if(display) { this.setState({ type: 'text' }); }
        else { this.setState({type: 'password'}); }
      }
    }

    const onChangeHandler = (e) => {
      this.value = e.target.value;
      if(!this.state.isDirty) {
        this.setState({ isDirty: true });
      } 

      this.validate(e);
    }

    const onBlurHandler = (e) => {
      this.validate(e);
    }

    const Icon = () => {
      let iconElement;
      let children = [];
      if(this.props.children) {
        if(this.props.children.length) { children = this.props.children; }
        else { children.push(this.props.children); }
      }

      children.forEach((c) => {
        //if(c['type']['name'].indexOf('Icon') !== -1) {
          iconElement = React.cloneElement(c);
        //}
      })

      return (
        <>
          { (iconElement !== undefined) && iconElement }
        </>
      )
    }

    return (
      <div className={$({'has-error': hasErrors}, "form-group")} aria-live="polite">
        <label className={$({"cb-required": isRequired})} htmlFor={id}>{label}</label><Icon />
        <div className="cb-input-state">
          <input 
            ref={this.inputRef} 
            className="form-control" 
            type={type} 
            name={id} 
            id={id} 
            maxLength={maxLength} 
            defaultValue={defaultValue} 
            autoComplete={autoComplete}
            aria-required={isRequired}
            aria-invalid={hasErrors}
            onChange={onChangeHandler} 
            onFocus={(e) => { onSecretHandler(e, true); }}
            onMouseOver={(e) => { onSecretHandler(e, true); }}
            onMouseOut={(e) => { onSecretHandler(e, false); }}
            onBlur={(e) => {
              onSecretHandler(e, false); 
              onBlurHandler(e);
            }}
          />
          { (hasErrors === true) && <i className="cb-glyph cb-x-mark" aria-hidden="true"></i> }
        </div>
        { (hasErrors === true) && <p className="cb-error-msg">{errorMessage}</p> }
      </div>
    )
  }

  componentDidMount() {
    this.value = this.inputRef.current.value;
  }

  componentDidUpdate(prevProps) {
    if(!isEqual(this.props.errorInfo, prevProps.errorInfo)) {
      this.setState({
        hasErrors: this.props.errorMessage ? true : false,
        errorMessage: this.props.errorMessage
      });
    }

    if(this.props.isRequired !== prevProps.isRequired) {
      let validationList = this.props.validators || [];
      if(this.props.isRequired) { validationList.push('required'); }
      this.validators = getValidators(validationList);

      this.validate({
        target: {
          value: this.inputRef.current.value
        }
      }, this.props.additionalData);
    }
  }
}

export
class ApricotLoader extends Component {
  

  render() {
    const { message } = this.props;

    return (
      <div className={$(styles["overlay"])}>
        <div className={$(styles["background"])}></div>
        <div className={$(styles["custom-modal"])}>
          <div class={$(styles["custom-modal-message"])}>
            <div role="progressbar" aria-valuetext="Processing Payment..." class="cb-loader cb-loader-blue cb-loader-lg"></div>
            <p>{ message ? message : 'Please wait while we process your payment...' }</p>
          </div>
        </div>
      </div>
    )
  }
}

export
class ApricotErrorView extends Component {
  

  render() {
    const { errorTitle, errorMessage, errors, linkForm } = this.props;

    return (
      <div className="alert alert-danger cb-alert-heading" role="alert">
        { (errorTitle !== undefined) && <h2>{errorTitle}</h2> }
        { (errorMessage !== undefined) && <p>{errorMessage}</p> }  
        { (errors !== undefined) && 
          <ul>
            { errors.map((e) => {
                return (
                  <li>
                    { (linkForm === true && e.id !== undefined) 
                      ? <a href={'#' + e.id}><strong>{e.error}</strong></a>
                      : <strong>{e.error}</strong>
                    } 
                  </li> 
                )
            })}
          </ul>
        }
        { this.props.children }
      </div>
    )
  }
}